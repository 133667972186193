import type { NextPage } from "next";
import Head from "next/head";
import Link from "next/link";
import { GetStaticProps } from "next";
import client from "../lib/apollo";
import GET_NAV_DATA from "../queries/getNavData";
import Header from "../components/Layout/Header";
import styled from "styled-components";

import { NavDataQuery as NavDataType } from "../generated/gql/graphql";
import { Footer } from "../components/Layout/Footer";

const Grid = styled.div`
  margin: 0 auto;
`;

const PageTitle = styled.div`
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 140px;
  font-size: 24px;
  margin-bottom: 20px;
  letter-spacing: 0.15em;
`;

const ContactContainer = styled.div`
  background: white;
  margin: auto;
`;

const GoHome = styled.div`
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  font-size: 14px;
  margin-bottom: 14px;
  letter-spacing: 0.15em;
  text-decoration: underline;
`;

interface AboutProps {
  navData: NavDataType;
}

export default function ErrorPage({ navData }: AboutProps) {
  return (
    <div>
      <Head>
        <title>Rannveig Óla Portfolio</title>
        <meta name="description" content="website" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header navData={navData} highlighted={""}></Header>
      <Grid>
        <ContactContainer>
          <PageTitle>{"PAGE NOT FOUND"}</PageTitle>
          <GoHome>
            <Link href="/">GO BACK TO MY PROJECTS</Link>
          </GoHome>
        </ContactContainer>
      </Grid>
      <Footer isFixed={true}></Footer>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const navData = await client.query<NavDataType>({
    query: GET_NAV_DATA,
  });
  return {
    props: {
      navData: navData.data,
    },
  };
};
